import React, { useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Alert, Card, ListGroup, ListGroupItem, Button, Modal, Form, ModalDialog, Stack } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { UserContext } from "../context/UserContext"
import Draggable from "react-draggable";
import CameraArrow from "./CameraArrow";
import { FaEdit } from "react-icons/fa"


class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".modal-header"><ModalDialog {...this.props} />
        </Draggable>
    }
}

const GENERIC_ERROR_MESSAGE = "Oops... Something went wrong!";

export default function Camera(props) {
    const [cameraQACheck, setCameraQACheck] = useState(false)
    const [cameraConnectionCheck, setCameraConnectionCheck] = useState(false)
    const [showInsertCameraScanModal, setShowInsertCameraScanModal] = useState(false)
    const [showCameraPreview, setShowCameraPreview] = useState(false)
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [showEditCameraPositionModal, setShowEditCameraPositionModal] = useState(false)
    const [showResetCameraModal, setShowResetCameraModal] = useState(false)
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [cameraInfo, setCameraInfo] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [nautobotJobId, setNautobotJobId] = useState(null)
    const [nautobotJobTimeElapsed, setNautobotJobTimeElapsed] = useState(0)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const { register: registerCameraPositionForm, handleSubmit: handleSubmitCameraPositionForm, setValue: setValueCameraPositionForm } = useForm()

    const { token, userenv, setToken } = useContext(UserContext)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/cameras/${props.cameraId}`,
            {
                headers: new Headers({
                    "Authorization": "Bearer " + token
                })
            })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                } else return response.json();
            })
            .then(camera => {
                setCameraInfo(camera)
                setCameraQACheck(camera.qa_check)
                setCameraConnectionCheck(camera.connection_check)
            })
    }, [props.storeId, props.cameraId, token, userenv, setToken])

    useEffect(() => {
        if (!showCameraPreview) return;
        setImageUrl(null);
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/cameras/${props.cameraId}/preview`,
            {
                headers: new Headers({
                    "Authorization": "Bearer " + token
                })
            })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                } else if (response.status === 400) {
                    return null;
                } else return response.blob();
            })
            .then(image => {
                if (image === null) {
                    setImageUrl("404")
                } else {
                    setImageUrl(URL.createObjectURL(image))
                }
            })
    }, [props.storeId, props.cameraId, token, setToken, userenv, showCameraPreview])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!nautobotJobId) return;  // No job id, no need to check
            try {
                const response = await fetch(`
                ${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
                    `/cameras/${props.cameraId}/ip-address?nautobot_job_id=${nautobotJobId}`,
                    {
                        method: 'PATCH',
                        headers: new Headers({
                            "Authorization": "Bearer " + token
                        })
                    }
                )
                if (response.status === 401) {
                    setToken(null);
                } else if (response.ok) {
                    const response_json = await response.json();
                    const job_status = response_json.job_status;
                    if (job_status === "SUCCESS") {
                        if (response_json.ip_address) {
                            let newCameraInfo = cameraInfo
                            newCameraInfo.ip_address = response_json.ip_address
                            setCameraInfo(newCameraInfo)
                            setCameraConnectionCheck(true)
                            setNautobotJobId(null)
                            setNautobotJobTimeElapsed(0)
                            setShowInsertCameraScanModal(false)
                        }
                    }
                    setNautobotJobTimeElapsed(nautobotJobTimeElapsed + 5);
                } else if (!response.ok) {
                    setErrorMessage(GENERIC_ERROR_MESSAGE);
                    setShowErrorAlert(true);
                }
            } catch (err) {
                setErrorMessage(GENERIC_ERROR_MESSAGE);
                setShowErrorAlert(true);
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [nautobotJobId, props.storeId, props.cameraId, token, setToken, userenv, cameraInfo, nautobotJobTimeElapsed]);

    const handleQACheckChange = (e) => {
        setCameraQACheck(e.target.checked)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/cameras/${props.cameraId}/qa-check?qa_check_value=${e.target.checked}`, {
            method: 'PATCH',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                };
                if (!response.ok) {
                    setCameraQACheck(!e.target.checked)
                    alert(GENERIC_ERROR_MESSAGE)
                }
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }
    const handleClose = () => { 
        setShowErrorAlert(false);
        setShowInsertCameraScanModal(false);
        reset();
        setButtonsDisabled(false);
        setNautobotJobId(null);
        setNautobotJobTimeElapsed(0); 
    };

    const handleShow = () => { reset(); setShowErrorAlert(false); setShowInsertCameraScanModal(true) };

    const onSubmit = (data) => {
        var isResponseOK = false
        setButtonsDisabled(true)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/cameras/${props.cameraId}/scan?scan_value=${data.scanValue}`,
            {
                method: 'PATCH',
                headers: new Headers({
                    "Authorization": "Bearer " + token
                })
            })
            .then(response => {
                if (response.ok) {
                    isResponseOK = true
                } else {
                    setShowErrorAlert(true)
                }
                return response.json()
            }).then(response_json => {
                if (isResponseOK) {
                    let newCameraInfo = cameraInfo
                    newCameraInfo.scan = data.scanValue
                    newCameraInfo.mac_address = response_json.mac_address
                    newCameraInfo.backend_id = response_json.onboard_id
                    setNautobotJobId(response_json.nautobot_job_id)
                    setCameraInfo(newCameraInfo)
                } else {
                    setButtonsDisabled(false);
                    if (Array.isArray(response_json.detail)) {
                        setErrorMessage(response_json.detail[0].msg)
                    } else {
                        setErrorMessage(response_json.detail)
                    }
                }
            })
            .catch(err => { console.log(err); setButtonsDisabled(false); setShowErrorAlert(true); setErrorMessage(GENERIC_ERROR_MESSAGE); })
    }

    const onSubmitCameraPosition = (data) => {
        setButtonsDisabled(true)
        let isResponseOK = false
        let updateCameraPositionJson =
        {
            "camera_id": props.cameraId,
            "img_pos_x": Number(data.img_pos_x),
            "img_pos_y": Number(data.img_pos_y)
        }
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/cameras`,
            {
                method: 'PATCH',
                body: JSON.stringify(updateCameraPositionJson),
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            })
            .then(response => {
                if (response.status === 401) setToken(null)
                if (!response.ok) {
                    setShowErrorAlert(true)
                } else {
                    isResponseOK = true
                    props.clearCamerasFromCanvas()
                    props.fetchAndDrawCameras()
                }
                return response.json()
            }).then(response_json => {
                setButtonsDisabled(false)
                if (!isResponseOK) {
                    if (Array.isArray(response_json.detail)) {
                        setErrorMessage(response_json.detail[0].msg)
                    } else {
                        setErrorMessage(response_json.detail)
                    }
                }
            }).catch(err => { console.log(err); setButtonsDisabled(false); setShowErrorAlert(true); setErrorMessage(GENERIC_ERROR_MESSAGE); })
    }

    const onClickResetCamera = () => {
        let isResponseOK = false
        setButtonsDisabled(true)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/cameras/${props.cameraId}/reset`,
            {
                method: 'PATCH',
                headers: new Headers({
                    "Authorization": "Bearer " + token
                })
            })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                } else if (!response.ok) {
                    setShowErrorAlert(true)
                } else {
                    isResponseOK = true
                    let newCameraInfo = cameraInfo
                    newCameraInfo.mac_address = ""
                    newCameraInfo.backend_id = -1
                    setCameraInfo(newCameraInfo)
                    setShowResetCameraModal(false)
                }
                return response.json()
            }).then(response_json => {
                setButtonsDisabled(false)
                if (!isResponseOK) {
                    if (Array.isArray(response_json.detail)) {
                        setErrorMessage(response_json.detail[0].msg)
                    } else {
                        setErrorMessage(response_json.detail)
                    }
                }
            }).catch(err => { console.log(err); setButtonsDisabled(false); setShowErrorAlert(true); setErrorMessage(GENERIC_ERROR_MESSAGE); })
    }

    return (
        <div>

            {cameraInfo &&
                <Card className="App-labels mb-5 ">
                    <Card.Body className="Card-body">
                        <Card.Title className="mb-3">Camera {cameraInfo.backend_id} &nbsp;&nbsp;
                            <Button variant="primary" size="sm" onClick={() => setShowCameraPreview(true)}>
                                Preview
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => { setShowErrorAlert(false); setShowEditCameraPositionModal(true) }} size="sm">
                                <FaEdit />
                            </Button>
                        </Card.Title>

                        Properties
                        <ListGroup className="mt-3 mb-3">
                            <ListGroupItem> Synthetic Id: {cameraInfo.synthetic_id} </ListGroupItem>
                            <ListGroupItem> Position (pixels): {props.posX}, {props.posY} </ListGroupItem>
                            <ListGroupItem> Lens: {cameraInfo.lens}</ListGroupItem>
                            <ListGroupItem> Height: {cameraInfo.height}</ListGroupItem>
                            {cameraInfo.calib_height && <ListGroupItem> Calib. Height: {cameraInfo.calib_height}</ListGroupItem>}
                            <ListGroupItem> Pitch: {cameraInfo.pitch}&deg;</ListGroupItem>
                            {!cameraInfo.roles.includes("top_view") && <ListGroupItem>
                                <Stack direction="horizontal" gap={3}><div>Yaw: {cameraInfo.yaw}&deg;</div>
                                    <CameraArrow yaw={cameraInfo.yaw} /></Stack>
                            </ListGroupItem>}
                            <ListGroupItem> Roles: {cameraInfo.roles} </ListGroupItem>
                        </ListGroup>
                        Deployment  &nbsp;&nbsp;
                        <Button variant="primary" size="sm" onClick={handleShow}>
                            Scan
                        </Button>
                        &nbsp;&nbsp;
                        <Button variant="primary" size="sm" onClick={() => { setShowErrorAlert(false); setShowResetCameraModal(true) }}>
                            Reset
                        </Button>
                        {cameraInfo.mac_address !== "" &&
                            <ListGroup className="mt-3 mb-3">
                                <ListGroupItem> Mac Address: {cameraInfo.mac_address} </ListGroupItem>
                                {cameraInfo.ip_address !== "" &&
                                    <ListGroupItem> IP Address: {cameraInfo.ip_address} </ListGroupItem>}
                                {cameraInfo.ip_address !== "" &&
                                    <ListGroupItem> Stream: <a href={"rtsp://" + cameraInfo.ip_address + "/stream1"}>rtsp://{cameraInfo.ip_address}/stream1</a></ListGroupItem>}
                                {cameraInfo.mac_address !== "" && <ListGroupItem> Backend Id: {cameraInfo.backend_id} </ListGroupItem>}
                            </ListGroup>}
                        {cameraInfo.mac_address !== "" &&
                            <p>Validation</p>
                        }
                        {cameraInfo.mac_address !== "" &&
                            <Form>
                                <ListGroup className="mt-3 mb-3">
                                    <ListGroupItem>
                                        Connection Check: &nbsp;
                                        <Form.Check inline disabled checked={cameraConnectionCheck} type="checkbox" id="connection-check-switch" />
                                    </ListGroupItem>
                                    <ListGroupItem> QA Check: &nbsp;
                                        <Form.Check inline checked={cameraQACheck}
                                            onChange={handleQACheckChange}
                                            type="checkbox" id="qa-check-switch" />
                                    </ListGroupItem>
                                </ListGroup>
                            </Form>
                        }
                    </Card.Body>
                </Card>
            }
            {!cameraInfo &&
                <Card className="App-labels mb-5">
                    <Card.Body className="Card-body">
                        <Card.Title className="mb-4">Camera Details</Card.Title>
                        <Alert variant="warning">
                            Fetching camera details...
                        </Alert>
                    </Card.Body>
                </Card>
            }

            <Modal size="lg" dialogAs={DraggableModalDialog} backdrop="static" show={showCameraPreview} onHide={() => setShowCameraPreview(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Camera Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {imageUrl === null &&
                        <Alert variant="warning">
                            Fetching preview...
                        </Alert>
                    }
                    {imageUrl === "404" &&
                        <Alert variant="danger">
                            Camera preview not available.
                        </Alert>
                    }
                    {imageUrl !== null && imageUrl !== "404" &&
                        <img src={imageUrl} alt="Camera Preview" width="100%" />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowCameraPreview(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showInsertCameraScanModal} onHide={handleClose}>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Scan Camera</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.ScanValue">
                            <Form.Control type="text" placeholder="<scan value>" autoFocus
                                {...register("scanValue", { required: true })}
                                isInvalid={errors.scanValue} />
                            <Form.Control.Feedback type="invalid">
                                Please fill in a valid scan value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {nautobotJobId &&
                            <Alert className="mt-3" variant="warning">
                                Waiting for ip assignment job ({nautobotJobId}) to complete...
                                <br />
                                Time elapsed: {nautobotJobTimeElapsed} seconds
                            </Alert>}
                        {nautobotJobId &&
                            <Alert className="mt-3" variant="danger">
                                Warning: Do not plug the camera network cable and don't close this window until the job is completed!
                            </Alert>}
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorMessage === "" ? GENERIC_ERROR_MESSAGE : errorMessage}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={buttonsDisabled} type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal backdrop="static" show={showResetCameraModal} onHide={() => setShowResetCameraModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Camera</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to reset this camera ?
                    {showErrorAlert &&
                        <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                            {errorMessage === "" ? GENERIC_ERROR_MESSAGE : errorMessage}
                        </Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={buttonsDisabled} type="submit" onClick={onClickResetCamera} variant="primary">
                        Yes
                    </Button>
                    <Button disabled={buttonsDisabled} type="submit" onClick={() => setShowResetCameraModal(false)} variant="secondary">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showEditCameraPositionModal} onHide={() => setShowEditCameraPositionModal(false)}>
                <Form noValidate onSubmit={handleSubmitCameraPositionForm(onSubmitCameraPosition)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Camera Position</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.EditCameraPosition">
                            {setValueCameraPositionForm("img_pos_x", props.posX)}
                            <Form.Label>Position X:</Form.Label>
                            <Form.Control type="text"  {...registerCameraPositionForm("img_pos_x")} />
                            {setValueCameraPositionForm("img_pos_y", props.posY)}
                            <Form.Label className="mt-3" >Position Y:</Form.Label>
                            <Form.Control type="text"  {...registerCameraPositionForm("img_pos_y")} />
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorMessage === "" ? GENERIC_ERROR_MESSAGE : errorMessage}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={buttonsDisabled} type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}